import React, { forwardRef, memo, useState } from "react";
import { fadeIn } from "../../../../utils/GlobalAnimations";
import { m } from "framer-motion";
import { Col, Container, Row } from "react-bootstrap";
import Buttons from "../../button/Buttons";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectCurrentLanguage, t } from "../../../store/slices/languageSlice";
import Utils from "../../../../utils/Utils";
import { signupForm } from "../../../store/slices/userSlice";
import { selectCountries } from "../../../store/slices/configSlice";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Autocomplete,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import FilePicker from "../../formFields/FilePicker";

const FormRegister = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { items, parent } = props;
  const lang = useSelector(selectCurrentLanguage);
  const countries = useSelector(selectCountries);
  const cl = Utils.isValidJson(parent.className)
    ? JSON.parse(parent.className)
    : parent.className;

  const [loading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const discover = [
    {
      value: "Feiras / Exposições",
      label: dispatch(t("WEBSITE.fair_expo")),
    },
    {
      value: "Seminários / Workshops",
      label: dispatch(t("WEBSITE.seminars_workshops")),
    },
    {
      value: "Internet",
      label: "Internet",
    },
    {
      value: "Email Marketing",
      label: dispatch(t("WEBSITE.email_marketing")),
    },
    {
      value: "Publicidade",
      label: dispatch(t("WEBSITE.publicity")),
    },
    {
      value: "Ouvi falar...",
      label: dispatch(t("WEBSITE.hear_about")),
    },
    {
      value: "Por outros fotógrafos",
      label: dispatch(t("WEBSITE.another_professional")),
    },
    {
      value: "Facebook",
      label: "Facebook",
    },
    {
      value: "Outros",
      label: dispatch(t("WEBSITE.others")),
    },
  ];
  const langList = [
    {
      value: "1",
      label: "Português",
    },
    {
      value: "2",
      label: "Espanhol",
    },
    {
      value: "3",
      label: "Inglês",
    },
    {
      value: "4",
      label: "Francês",
    },
  ];

  const work_with = [
    {
      value: "Casamento",
      label: dispatch(t("WEBSITE.wedding")),
    },
    {
      value: "Estudio",
      label: dispatch(t("WEBSITE.studio")),
    },
    {
      value: "Newborn",
      label: dispatch(t("WEBSITE.newborn")),
    },
    {
      value: "Seniors",
      label: dispatch(t("WEBSITE.seniors")),
    },
    {
      value: "Boudoir",
      label: dispatch(t("WEBSITE.boudoir")),
    },
    {
      value: "Produto",
      label: dispatch(t("WEBSITE.product")),
    },
  ];

  const schema = yup.object().shape({
    clt_CommercialName: yup
      .string()
      .required(dispatch(t("common.input_required"))),
    clt_Address_A: yup.string().required(dispatch(t("common.input_required"))),
    clt_City_A: yup.string().required(dispatch(t("common.input_required"))),
    clt_ZipCode1_A: yup
      .string()
      .required(dispatch(t("common.input_required")))
      .when("clt_Country_A", {
        is: "pt",
        then: (schema) =>
          schema.matches(
            /^\d{4}-\d{3}$/,
            dispatch(
              t("WEBSITE.invalid_cep_format", {
                format: "XXXX-XXX",
              })
            )
          ),
        otherwise: (schema) => schema,
      }),
    // clt_ZipCode2_A: yup.string().required(dispatch(t("common.input_required"))),
    clt_Country_A: yup.string().required(dispatch(t("common.input_required"))),
    clt_ContactPerson: yup
      .string()
      .required(dispatch(t("common.input_required"))),
    clt_Phone: yup
      .string()
      .required(dispatch(t("common.input_required")))
      .matches(/^[0-9]+$/, dispatch(t("common.invalid_chars"))),
    clt_Email: yup
      .string()
      .required(dispatch(t("common.input_required")))
      .email(dispatch(t("common.input_invalid_email"))),
    clt_NIF: yup.string().required(dispatch(t("common.input_required"))),
    clt_LangId: yup.string().required(dispatch(t("common.input_required"))),
    ced_comments2: yup.object().shape({
      i_found_out: yup.string().required(dispatch(t("common.input_required"))),
      work_with: yup
        .array()
        .of(yup.string())
        .min(1, dispatch(t("common.min_value", { value: 1 })))
        .required(dispatch(t("common.input_required"))),
      website: yup
        .string()
        // .matches(
        //   /^(www\.)[a-zA-Z0-9-]+(\.[a-zA-Z]{2,}){1,2}$/,
        //   dispatch(t("common.invalid_url"))
        // )
        .required(dispatch(t("common.input_required"))),
      instagram: yup.string().notRequired().nullable(),
      person_sales: yup.string().required(dispatch(t("common.input_required"))),
      percentage_sessions: yup
        .string()
        .required(dispatch(t("common.input_required"))),
      video_call: yup.string().required(dispatch(t("common.input_required"))),
    }),
    file_i: yup.mixed().when("clt_Country_A", {
      is: "es",
      then: (schema) => schema.required(dispatch(t("common.input_required"))),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
    file_ii: yup.mixed().when("clt_Country_A", {
      is: "es",
      then: (schema) => schema.required(dispatch(t("common.input_required"))),
      otherwise: (schema) => schema.notRequired().nullable(),
    }),
    privacy: yup
      .boolean()
      .required(dispatch(t("common.input_required")))
      .test({
        message: dispatch(t("common.input_required")),
        name: "privacy-policy",
        test: (v, c) => {
          return v;
        },
      }),
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    trigger,
    formState: { errors, dirtyFields, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      clt_CommercialName: "",
      clt_Address_A: "",
      clt_City_A: "",
      clt_ZipCode1_A: "",
      // clt_ZipCode2_A: "",
      clt_Country_A: null,
      clt_ContactPerson: "",
      clt_Phone: "",
      clt_Email: "",
      clt_NIF: "",
      clt_LangId: null,
      ced_comments2: {
        i_found_out: null,
        work_with: [],
        website: "",
        instagram: "",
        percentage_sessions: "",
        person_sales: "",
        video_call: "",
      },
      country_name: "",
      prefix: "",
      file_i: null,
      file_ii: null,
      privacy: false,
    },
    resolver: yupResolver(schema),
  });

  const form = watch();

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const {
        prefix,
        clt_Phone,
        ced_comments2: {
          i_found_out,
          work_with,
          website,
          instagram,
          percentage_sessions,
          person_sales,
          video_call,
        },
        file_i,
        file_ii,
      } = data;

      const ced_comments2 = `Website: ${website} </br> Como conheceu a Floricolor: ${i_found_out}</br> Faz fotografia de: ${work_with.join(
        ", "
      )};</br> Instagram: ${instagram}  </br> Faz In Person Sales (Vendas presenciais)?: ${
        person_sales === "no" ? "Não" : "Sim"
      } </br> 
      Gostaria de agendar uma video chamada com um dos nossos consultores?: ${
        video_call === "no" ? "Não" : "Sim"
      } 
      </br> Indique a percentagem de sessões em que vende produtos: ${percentage_sessions}
      `;

      dispatch(
        signupForm({
          ...data,
          comments_split: {
            i_found_out,
            work_with: work_with.join(","),
            website,
            instagram,
            percentage_sessions,
            person_sales,
            video_call,
          },
          ced_comments2,
          clt_Phone: `${prefix}${clt_Phone}`,
          clt_ZipCode1_A:
            data.clt_Country_A === "pt"
              ? data.clt_ZipCode1_A.split("-")[0]
              : data.clt_ZipCode1_A,
          clt_ZipCode2_A:
            data.clt_Country_A === "pt"
              ? data.clt_ZipCode1_A.split("-")[1]
              : "",
          ...(data.clt_Country_A === "es" ? { file_i, file_ii } : {}),
        })
      ).then((res) => {
        setIsLoading(false);

        if (res.payload) setSuccess(true);
      });
    } catch (error) {
      setSuccess(false);
      setIsLoading(false);
    }
  };

  return (
    <m.section
      className="py-[10px] overflow-hidden"
      {...fadeIn}
      style={{
        ...cl,
      }}
    >
      <Container>
        {success && (
          <Row className="justify-center">
            <Col xl={9} md={12} sm={12} className=" px-[10px] ">
              <div className="w-full flex flex-col items-center">
                <Typography
                  color={"text.secondary"}
                  className="whitespace-pre-wrap"
                  style={{
                    fontWeight: 600,
                    fontSize: "1.5rem",
                  }}
                >
                  {dispatch(t("WEBSITE.register_success"))}
                </Typography>
                <Typography
                  color={"text.secondary"}
                  className="whitespace-pre-wrap"
                >
                  {dispatch(t("WEBSITE.register_success_description"))}
                </Typography>
              </div>
            </Col>
          </Row>
        )}

        {!success && (
          <Row className="justify-center">
            <Col xl={9} md={12} sm={12} className=" px-[10px] ">
              <div className="w-full">
                <Typography
                  color={"text.secondary"}
                  className="mb-4 whitespace-pre-wrap"
                >
                  {dispatch(t("WEBSITE.form_info"))}
                </Typography>
              </div>
            </Col>
            <Col xl={9} md={12} sm={12} className="px-[10px] sm:mb-[40px]">
              <div className="p-16 border border-mediumgray lg:mt-[35px] md:p-10 space-y-8">
                <Controller
                  control={control}
                  name={"clt_CommercialName"}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={dispatch(t("WEBSITE.clt_CommercialName"))}
                      placeholder={dispatch(t("WEBSITE.clt_CommercialName"))}
                      fullWidth
                      helperText={errors?.clt_CommercialName?.message}
                      error={!!errors?.clt_CommercialName}
                      required
                    />
                  )}
                />

                <div className="w-full flex sm:!flex-col flex-row space-x-2 sm:space-x-0 sm:space-y-8 space-y-0">
                  <Controller
                    control={control}
                    name={"clt_Address_A"}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={dispatch(t("WEBSITE.clt_Address_A"))}
                        placeholder={dispatch(t("WEBSITE.clt_Address_A"))}
                        fullWidth
                        helperText={errors?.clt_Address_A?.message}
                        error={!!errors?.clt_Address_A}
                        required
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={"clt_City_A"}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={dispatch(t("WEBSITE.clt_City_A"))}
                        placeholder={dispatch(t("WEBSITE.clt_City_A"))}
                        fullWidth
                        helperText={errors?.clt_City_A?.message}
                        error={!!errors?.clt_City_A}
                        required
                      />
                    )}
                  />
                </div>

                <div className="w-full flex sm:!flex-col flex-row space-x-2 sm:space-x-0 sm:space-y-8 space-y-0">
                  <div className="w-full sm:w-full flex space-x-2 ">
                    <Controller
                      control={control}
                      name={"clt_ZipCode1_A"}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label={dispatch(t("WEBSITE.clt_ZipCode"))}
                          placeholder={dispatch(t("WEBSITE.clt_ZipCode"))}
                          fullWidth
                          helperText={errors?.clt_ZipCode1_A?.message}
                          error={!!errors?.clt_ZipCode1_A}
                          required
                        />
                      )}
                    />

                    {/* <Controller
                      control={control}
                      name={"clt_ZipCode2_A"}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className="w-[50%] "
                          helperText={errors?.clt_ZipCode2_A?.message}
                          error={!!errors?.clt_ZipCode2_A}
                          required
                        />
                      )}
                    /> */}
                  </div>

                  <Controller
                    control={control}
                    name={"clt_Country_A"}
                    render={({ field: { onChange, value, ...field } }) => (
                      <Autocomplete
                        {...field}
                        options={countries}
                        value={
                          value
                            ? _.find(countries, { value: value.value || value })
                            : null
                        }
                        onChange={(e, nv) => {
                          if (nv) {
                            // if (nv.value === "es" || value === "es") trigger();

                            onChange(nv.value);
                            setValue("country_name", nv.label);
                            setValue("prefix", nv.prefix);
                            return;
                          }

                          setValue("country_name", "");
                          setValue("prefix", "");
                          onChange(nv);
                          return;
                        }}
                        getOptionLabel={(opt) => opt.label}
                        isOptionEqualToValue={(opt, v) =>
                          v && opt.value == (v.value || v)
                        }
                        className="w-full"
                        renderInput={(_params) => (
                          <TextField
                            {..._params}
                            label={dispatch(t("WEBSITE.clt_Country_A"))}
                            placeholder={dispatch(t("WEBSITE.clt_Country_A"))}
                            className="w-full"
                            helperText={errors?.clt_Country_A?.message}
                            error={!!errors?.clt_Country_A}
                            required
                          />
                        )}
                      />
                    )}
                  />
                </div>

                <div className="w-full flex sm:!flex-col flex-row space-x-2 sm:space-x-0 sm:space-y-8 space-y-0">
                  <Controller
                    control={control}
                    name={"clt_ContactPerson"}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={dispatch(t("WEBSITE.clt_ContactPerson"))}
                        placeholder={dispatch(t("WEBSITE.clt_ContactPerson"))}
                        fullWidth
                        helperText={errors?.clt_ContactPerson?.message}
                        error={!!errors?.clt_ContactPerson}
                        required
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={"clt_Phone"}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={dispatch(t("WEBSITE.clt_Phone"))}
                        placeholder={dispatch(t("WEBSITE.clt_Phone"))}
                        fullWidth
                        type="number"
                        helperText={errors?.clt_Phone?.message}
                        error={!!errors?.clt_Phone}
                        required
                        InputProps={{
                          startAdornment: form.prefix && (
                            <Typography
                              color={"text.secondary"}
                              className={"w-16"}
                            >
                              {form.prefix}
                            </Typography>
                          ),
                        }}
                      />
                    )}
                  />
                </div>

                <Controller
                  control={control}
                  name={"clt_Email"}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={dispatch(t("WEBSITE.clt_Email"))}
                      placeholder={dispatch(t("WEBSITE.clt_Email"))}
                      fullWidth
                      helperText={errors?.clt_Email?.message}
                      error={!!errors?.clt_Email}
                      required
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={"clt_NIF"}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={dispatch(t("WEBSITE.clt_NIF"))}
                      placeholder={dispatch(t("WEBSITE.clt_NIF"))}
                      fullWidth
                      helperText={errors?.clt_NIF?.message}
                      error={!!errors?.clt_NIF}
                      required
                    />
                  )}
                />

                <Divider />

                {form.clt_Country_A === "es" && (
                  <div className="w-full space-y-5">
                    <Typography
                      color={"text.secondary"}
                      style={{
                        fontSize: 11,
                      }}
                    >
                      {dispatch(t("WEBSITE.file_title"))}
                    </Typography>

                    <Controller
                      control={control}
                      name={"file_i"}
                      render={({ field }) => (
                        <FilePicker
                          {...field}
                          errors={errors}
                          title={dispatch(t("WEBSITE.file_i"))}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name={"file_ii"}
                      render={({ field }) => (
                        <FilePicker
                          {...field}
                          errors={errors}
                          title={dispatch(t("WEBSITE.file_ii"))}
                        />
                      )}
                    />

                    <Typography
                      color={"text.secondary"}
                      style={{
                        fontSize: 11,
                      }}
                    >
                      {dispatch(t("WEBSITE.file_obs"))}
                    </Typography>

                    <Divider />
                  </div>
                )}

                <Controller
                  control={control}
                  name={"clt_LangId"}
                  render={({ field: { onChange, value, ...field } }) => (
                    <Autocomplete
                      {...field}
                      options={langList}
                      value={
                        value
                          ? _.find(langList, { value: value.value || value })
                          : null
                      }
                      onChange={(e, nv) => {
                        if (nv) {
                          onChange(nv.value);
                          return;
                        }

                        onChange(nv);
                        return;
                      }}
                      getOptionLabel={(opt) => opt.label}
                      isOptionEqualToValue={(opt, v) =>
                        v && opt.value == (v.value || v)
                      }
                      fullWidth
                      renderInput={(_params) => (
                        <TextField
                          {..._params}
                          label={dispatch(t("WEBSITE.clt_Lang"))}
                          placeholder={dispatch(t("WEBSITE.clt_Lang"))}
                          fullWidth
                          helperText={errors?.clt_LangId?.message}
                          error={!!errors?.clt_LangId}
                          required
                        />
                      )}
                    />
                  )}
                />

                <Controller
                  control={control}
                  name={"ced_comments2.i_found_out"}
                  render={({ field: { onChange, value, ...field } }) => (
                    <Autocomplete
                      {...field}
                      options={discover}
                      value={
                        value
                          ? _.find(discover, { value: value.value || value })
                          : null
                      }
                      onChange={(e, nv) => {
                        if (nv) {
                          onChange(nv.value);
                          return;
                        }

                        onChange(nv);
                        return;
                      }}
                      getOptionLabel={(opt) => opt.label}
                      isOptionEqualToValue={(opt, v) =>
                        v && opt.value == (v.value || v)
                      }
                      fullWidth
                      renderInput={(_params) => (
                        <TextField
                          {..._params}
                          label={dispatch(t("WEBSITE.i_found_out"))}
                          placeholder={dispatch(t("WEBSITE.i_found_out"))}
                          fullWidth
                          helperText={
                            errors?.ced_comments2?.i_found_out?.message
                          }
                          error={!!errors?.ced_comments2?.i_found_out}
                          required
                        />
                      )}
                    />
                  )}
                />

                <div className="w-full flex sm:!flex-col flex-row space-x-2 sm:space-x-0 sm:space-y-8 space-y-0">
                  <Controller
                    control={control}
                    name={"ced_comments2.website"}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={dispatch(t("WEBSITE.website"))}
                        placeholder={dispatch(t("WEBSITE.website"))}
                        fullWidth
                        helperText={errors?.ced_comments2?.website?.message}
                        error={!!errors?.ced_comments2?.website}
                        required
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    name={"ced_comments2.instagram"}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label={dispatch(t("WEBSITE.instagram"))}
                        placeholder={dispatch(t("WEBSITE.instagram"))}
                        fullWidth
                        helperText={errors?.ced_comments2?.instagram?.message}
                        error={!!errors?.ced_comments2?.instagram}
                        required
                      />
                    )}
                  />
                </div>

                <div className="w-full flex flex-col space-y-2">
                  <Typography color={"text.secondary"}>
                    {`${dispatch(t("WEBSITE.work_with"))} *`}
                  </Typography>
                  <Controller
                    control={control}
                    name={"ced_comments2.work_with"}
                    render={({ field: { onChange, value, ...field } }) => (
                      <FormGroup row>
                        {work_with.map((i, _index) => {
                          const selected = value?.some((v) => v == i.value);

                          return (
                            <FormControlLabel
                              key={i.value}
                              control={
                                <Checkbox
                                  checked={selected}
                                  name={i.value}
                                  onChange={(e, c) => {
                                    const items = [...value];

                                    if (c) {
                                      items.push(i.value);
                                      onChange([...items]);
                                      return;
                                    }

                                    items.splice(
                                      items.findIndex((it) => it == i.value),
                                      1
                                    );

                                    onChange([...items]);
                                    return;
                                  }}
                                />
                              }
                              label={i.label}
                            />
                          );
                        })}
                      </FormGroup>
                    )}
                  />
                </div>

                <div className="w-full flex flex-col space-y-2">
                  <Typography color={"text.secondary"}>
                    {`${dispatch(t("WEBSITE.person_sales"))} *`}
                  </Typography>
                  <Controller
                    control={control}
                    name={"ced_comments2.person_sales"}
                    render={({ field: { onChange, value, ...field } }) => (
                      <RadioGroup
                        row
                        {...field}
                        value={`${value}`}
                        onChange={(e, v) => {
                          onChange(v);
                        }}
                        name={"ced_comments2.person_sales"}
                      >
                        <FormControlLabel
                          value={"yes"}
                          control={<Radio />}
                          label={dispatch(t("common.yes"))}
                        />
                        <FormControlLabel
                          value={"no"}
                          control={<Radio />}
                          label={dispatch(t("common.no"))}
                        />
                      </RadioGroup>
                    )}
                  />
                </div>

                <Controller
                  control={control}
                  name={"ced_comments2.percentage_sessions"}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={dispatch(t("WEBSITE.percentage_sessions"))}
                      placeholder={dispatch(t("WEBSITE.percentage_sessions"))}
                      fullWidth
                      helperText={
                        errors?.ced_comments2?.percentage_sessions?.message
                      }
                      error={!!errors?.ced_comments2?.percentage_sessions}
                      required
                    />
                  )}
                />

                <div className="w-full flex flex-col space-y-2">
                  <Typography color={"text.secondary"}>
                    {`${dispatch(t("WEBSITE.video_call"))} *`}
                  </Typography>
                  <Controller
                    control={control}
                    name={"ced_comments2.video_call"}
                    render={({ field: { onChange, value, ...field } }) => (
                      <RadioGroup
                        row
                        {...field}
                        value={`${value}`}
                        onChange={(e, v) => {
                          onChange(v);
                        }}
                        name={"ced_comments2.video_call"}
                      >
                        <FormControlLabel
                          value={"yes"}
                          control={<Radio />}
                          label={dispatch(t("common.yes"))}
                        />
                        <FormControlLabel
                          value={"no"}
                          control={<Radio />}
                          label={dispatch(t("common.no"))}
                        />
                      </RadioGroup>
                    )}
                  />
                </div>

                <Divider />

                <div className="w-full ">
                  <p className="block text-sm">
                    {dispatch(t("WEBSITE.privacy_policy_description"))}{" "}
                    <Link
                      aria-label="privacy-policy-link"
                      target="_blank"
                      to={dispatch(t("WEBSITE.privacy_policy_link"))}
                      className="underline"
                    >
                      {" "}
                      {dispatch(t("WEBSITE.privacy_policy"))}
                    </Link>
                  </p>
                  <Controller
                    control={control}
                    name={"privacy"}
                    render={({ field: { onChange, value, ...field } }) => (
                      <FormControlLabel
                        required
                        control={
                          <Checkbox
                            onChange={(e, c) => {
                              onChange(c);
                            }}
                            checked={Boolean(value)}
                            {...field}
                          />
                        }
                        label={dispatch(t("WEBSITE.agreed"))}
                      />
                    )}
                  />
                </div>

                <Buttons
                  type={"submit"}
                  ariaLabel="register"
                  onClick={handleSubmit(onSubmit)}
                  title={dispatch(t("WEBSITE.signup"))}
                  color={"#fff"}
                  disabled={_.isEmpty(dirtyFields) || !isValid || loading}
                />
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </m.section>
  );
});

export default memo(FormRegister);
